import { PrismicImage, PrismicPageDefinition, PrismicRichText } from "./types";

export type TypeGlobalCDNWAFService = {
  service_image: PrismicImage;
  service_title: string;
  service_description: string;
};

export type TypeGlobalCDNWAFServiceLower = {
  service_image_lower: PrismicImage;
  service_title_lower: string;
  service_description_lower: string;
};

export type TypeGlobalCDNWAFPage = {
  title: PrismicRichText;
  meta_title: string;
  subtitle: PrismicRichText;

  section_title: string;
  section_title1: string;
  section_title2: string;
  section_title_lower: string;
  section_subtitle_lower: PrismicRichText;

  section_subtitle: PrismicRichText;
  section_subtitle1: PrismicRichText;
  section_text: PrismicRichText;
  section_text1: PrismicRichText;

  cta: string;
  subtitle1: string;
  button_text: string;
  button_link: PrismicPageDefinition;
  second_button_text: string;
  second_button_link: PrismicPageDefinition;

  services: TypeGlobalCDNWAFService[];
  services_lower: TypeGlobalCDNWAFServiceLower[];

  hero_image: PrismicImage;
  image: PrismicImage;
  image1: PrismicImage;

  page_meta_title: string;
  page_meta_thumbnail: PrismicImage;
  page_meta_description: string;
};

export type TypeGlobalCDNWAFQueryResult = {
  allPrismicGlobalCdnWaf: {
    edges: Array<{
      node: { data: TypeGlobalCDNWAFPage };
    }>;
  };
};

export const getPage = (
  data: TypeGlobalCDNWAFQueryResult
): TypeGlobalCDNWAFPage => {
  return data.allPrismicGlobalCdnWaf.edges[0].node.data;
};
