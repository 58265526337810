import { graphql, StaticQuery } from "gatsby";
import React from "react";
import "./cdn-waf.scss";
import {
  getPage,
  TypeGlobalCDNWAFPage,
  TypeGlobalCDNWAFQueryResult,
} from "../../utils/queries/global_cdn_waf";
import {
  PageHeaderCustomV2WhiteWide,
  PageHeaderStandard,
} from "../../components/page-header/page-header";
import Helmet from "react-helmet";
import { PageTitle } from "../../components/title/title";
import AmazeeIoMetadata from "../../components/metadata";
import RichTextRender from "../../utils/rich-text";
import AmazeePage from "../../components/layout";
import Linked from "../../utils/linkedItem";
import { AmazeeIOButton } from "../../components/button/button";
import usePreviewData from "../../utils/usePreviewData";

const query = graphql`
  {
    allPrismicGlobalCdnWaf {
      edges {
        node {
          data {
            page_meta_description
            section_subtitle {
              html
              raw
              text
            }
            section_subtitle1
            section_title
            section_title1
            section_text1 {
              html
              raw
              text
            }
            meta_title
            title {
              html
              raw
              text
            }
            hero_image {
              alt
              url
            }
            image {
              alt
              url
            }
            image1 {
              alt
              url
            }
            subtitle {
              html
              raw
              text
            }
            section_text {
              html
              raw
              text
            }
            services {
              service_title
              service_description
              service_image {
                alt
                url
              }
            }
            section_title_lower
            section_subtitle_lower
            services_lower {
              service_title_lower
              service_description_lower
              service_image_lower {
                alt
                url
              }
            }
            cta
            subtitle1
            button_text
            button_link {
              document {
                ... on PrismicPageDefinitions {
                  id
                  data {
                    url
                    friendly_name
                  }
                }
              }
            }
            second_button_text
            second_button_link {
              document {
                ... on PrismicPageDefinitions {
                  id
                  data {
                    url
                    friendly_name
                  }
                }
              }
            }
            page_meta_thumbnail {
              url
              alt
            }
          }
        }
      }
    }
  }
`;

const GlobalCDNWAFPage = ({
  data,
  location,
}: {
  location: Location;
  data: TypeGlobalCDNWAFQueryResult;
}) => {
  const pageData = getPage(data);
  if (!pageData) return null;
  const result = usePreviewData(
    pageData,
    "PrismicGlobalCdnWaf",
    true
  ) as TypeGlobalCDNWAFPage;

  const title = result.meta_title
    ? result.meta_title
    : RichTextRender(result.title.text);

  return (
    <AmazeePage location={location}>
      <div id="global-cdn-waf">
        <Helmet>
          <link rel="stylesheet" href="/css/carousel-single.min.css" />
          <link rel="stylesheet" href="/css/carousel-multi.min.css" />
        </Helmet>
        <PageTitle title={title} amazeeOnly={true} />
        <AmazeeIoMetadata
          title={RichTextRender(result.title.text)}
          page_meta_description={result.page_meta_description}
          page_meta_thumbnail={result.page_meta_thumbnail}
          location={location}
        />

        <PageHeaderStandard
          component={PageHeaderCustomV2WhiteWide}
          header={RichTextRender(result.title)}
          image={result.hero_image}
          subheader={result.subtitle}
          withSiteName={false}
          hasSectionDivider={false}
        />

        <section id="introduction">
          <div className="container">
            <div className="row flex justify-center items-center">
              <div className="col-12 col-lg-5 text-left">
                <img src={result.image.url} alt={result.image.alt} />
              </div>
              <div className="col-12 col-lg-5 text-left">
                <div>{RichTextRender(result.section_text)}</div>
              </div>
            </div>
            <div className="col-12 col-lg-10"></div>
          </div>
        </section>

        <section id="services">
          <div className="container">
            <div className="row flex justify-center">
              <div className="col-12 col-lg-10 text-center">
                <h2>{RichTextRender(result.section_title)}</h2>
              </div>
              <div className="col-12 col-lg-8 text-center">
                <p>{RichTextRender(result.section_subtitle)}</p>
              </div>
            </div>

            <div className="row">
              {result.services.map((service, index) => (
                <div key={index} className="col-12 col-lg-4 service">
                  <img
                    src={service.service_image.url}
                    alt={service.service_image.alt}
                  />
                  <h3 className="service-title">{service.service_title}</h3>
                  <p className="description">{service.service_description}</p>
                </div>
              ))}
            </div>
          </div>
        </section>

        <section id="protect-your-web-application">
          <div className="container">
            <div className="row flex justify-center"></div>
            <div className="row flex justify-center items-center">
              <div className="col-12 col-lg-6 text-left">
                <div className="description">
                  {RichTextRender(result.section_text1)}
                </div>
              </div>
              <div className="col-12 col-lg-4 text-center content-center">
                <img
                  src={result.image1.url}
                  alt={result.image1.alt}
                  loading="lazy"
                />
              </div>
            </div>

            <div className="col-12 col-lg-10"></div>
          </div>
        </section>

        <section id="services" className="services-lower">
          <div className="container">
            <div className="row flex justify-center">
              <div className="col-12 col-lg-10 text-center">
                <h2>{RichTextRender(result.section_title_lower)}</h2>
              </div>
              <div className="col-12 col-lg-8 text-center">
                <p>{RichTextRender(result.section_subtitle_lower)}</p>
              </div>
            </div>

            <div className="row">
              {result.services_lower.map((service_lower, index) => (
                <div key={index} className="col-12 col-lg-4 service">
                  <img
                    src={service_lower.service_image_lower.url}
                    alt={service_lower.service_image_lower.alt}
                    loading="lazy"
                  />
                  <h3 className="service-title">
                    {service_lower.service_title_lower}
                  </h3>
                  <p className="description">
                    {service_lower.service_description_lower}
                  </p>
                </div>
              ))}
            </div>
          </div>
        </section>

        <section className="cta larger-text" id="cta">
          <div className="container">
            <div className="row">
              <div className="col-12 d-flex justify-center flex-col items-center">
                <div>
                  <p className="cta-main container col-md-12 cta-big mb-0">
                    {result.cta}
                  </p>
                </div>
                <div>
                  <p className="container col-md-7 cta-big mb-0">
                    {result.subtitle1}
                  </p>
                </div>
                <div className="buttons">
                  <Linked link_to={result.button_link}>
                    <AmazeeIOButton classes="btn-boldish amazeei-button inverted inline-block width-auto mt-4">
                      {result.button_text}
                    </AmazeeIOButton>
                  </Linked>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </AmazeePage>
  );
};

const StaticTypeGlobalCDNWAFPage: React.FC<{ location: Location }> = ({
  location,
}) => {
  return (
    <StaticQuery
      query={query}
      render={(data) => <GlobalCDNWAFPage location={location} data={data} />}
    />
  );
};
export default StaticTypeGlobalCDNWAFPage;
